import dayjs from 'dayjs';
import { customAlphabet } from 'nanoid';
import { htmlToText } from 'html-to-text';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ResponseInterface } from 'shared/response.interface';
import { notifications } from '@mantine/notifications';
import { ReactNode } from 'react';
import { MantineColor } from '@mantine/core';
import recur from 'dayjs-recur';
import { FileUpload } from 'shared/types';
import { toFormData } from 'axios';
import { IconFileSignal, IconFileSpreadsheet, IconFileText, IconFileZip, IconPdf, IconPng } from '@tabler/icons-react';
import { LabelValuePair } from '@/interfaces';
// @ts-ignore
dayjs.extend(recur);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export function bytesToSize(bytes: number) {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes == 0) return '0 Byte';
	// @ts-ignore
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	if (i == 0) return bytes + ' ' + sizes[i];
	return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

export const convertToBase64 = (file: File) => {
	return URL.createObjectURL(file);
};

export function timeStampToDate(stamp: number, format = 'DD MMM, YYYY hh:mm:ss A') {
	return dayjs(stamp).format(format);
}

export function secondsToDhms(seconds: any) {
	seconds = Number(seconds);
	const d = Math.floor(seconds / (3600 * 24));
	const h = Math.floor((seconds % (3600 * 24)) / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = Math.floor(seconds % 60);
	return { d, h, m, s };
}

export function randomColors(i: number): { p: string; s: string } {
	const prepareColors = [
		{ p: 'rgb(128, 255, 165)', s: 'rgb(1, 191, 236)' },
		{ p: 'rgb(0, 221, 255)', s: 'rgb(77, 119, 255)' },
		{ p: 'rgb(55, 162, 255)', s: 'rgb(116, 21, 219)' },
		{ p: 'rgb(255, 0, 135)', s: 'rgb(135, 0, 157)' },
		{ p: 'rgb(255, 191, 0)', s: 'rgb(224, 62, 76)' },
		{ p: 'rgb(144, 164, 174)', s: 'rgb(55, 71, 79)' },
		{ p: 'rgb(255, 238, 88)', s: 'rgb(255, 143, 0)' },
	];
	return prepareColors[i];
}

export const distinctColors = [
	'#000000',
	'#f0e68c',
	'#006400',
	'#483d8b',
	'#008b8b',
	'#00008b',
	'#800080',
	'#ff0000',
	'#ff8c00',
	'#ffd700',
	'#00bfff',
	'#f4a460',
	'#ee82ee',
	'#0000ff',
	'#ff6347',
	'#1e90ff',
	'#00ffff',
	'#b03060',
	'#8fbc8f',
	'#32cd32',
	'#f4a460',
	'#808000',
	'#b03060',
	'#00ff00',
	'#adff2f',
	'#8a2be2',
	'#add8e6',
	'#98fb98',
	'#ffb6c1',
	'#a0522d',
	'#ff00ff',
	'#696969',
];

export function hexToRgb(hex: string) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	// @ts-ignore
	return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`;
}

export function convertHtmlToText(text: string) {
	return htmlToText(text);
}

export function phoneNumber(phone: string) {
	phone = phone.replace(/\s|\(|\)|-/g, '');
	if (!phone.startsWith('+') && phone.length === 10) return '+1' + phone;
	if (phone.length === 11) return '+' + phone;
	return phone;
}

export function generateReference(prefix: string, randLength = 6, format = 'YYMMDD', rand = 1) {
	const suffix = new Date()
		.getTime()
		.toString()
		.slice(-randLength + rand);
	return prefix + dayjs().format(format) + suffix + randomID(rand, 'N');
}

export function randomID(size = 24, type: 'A' | 'N' | 'AN' = 'AN') {
	const alpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const num = '0123456789';
	const nanoID = customAlphabet(type === 'A' ? alpha : type === 'N' ? num : alpha + num);
	return nanoID(size);
}

export function generateID() {
	const nanoid = customAlphabet('0123456789abcdef');
	return nanoid(24);
}

export function trimPhoneNo(no: string, withSpace?: boolean, withCode?: boolean, format?: string) {
	if (no) {
		no = no.replace(/(\+1)|[()\-\s]+/g, '').slice(-10);
		if (format) {
			return no.replace(/^(\d{3})(\d{3})(\d{4})$/, format);
		}
		if (withSpace) {
			return no.replace(/^(\d{3})(\d{3})(\d{4})$/, withCode ? '+1 $1 $2 $3' : '$1 $2 $3');
		}
	}
	return no;
}

export function currency(num: number, fractionDigits = 2) {
	return '$' + num.toFixed(fractionDigits);
}

export function convertDate(rawDate: Date | string, format?: string, inUtc?: boolean, notAvailable = 'N/A') {
	format = format || 'DD MMM, YYYY';
	return rawDate ? (inUtc ? dayjs(rawDate).utc().format(format) : dayjs(rawDate).format(format)) : notAvailable;
}

export function convertToDateTime(rawDate: Date | string, inUtc?: boolean, notAvailable = 'N/A') {
	return convertDate(rawDate, 'DD MMM, YYYY hh:mm:ss A', inUtc, notAvailable);
}

export function formatBytes(bytes: number, decimals = 2) {
	if (!+bytes) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function enumToPair(obj: any, withSpace?: boolean, excludes?: any[], only?: any[]): Array<LabelValuePair> {
	return Object.keys(obj)
		.filter((f: any) => isNaN(f) && !excludes?.includes(f))
		.filter((f: any) => (only ? only.includes(obj[f]) : true))
		.map((f) => ({
			value: obj[f],
			label: withSpace ? f.replace(/[A-Z]|\d+/g, ' $&') : f,
			_id: obj[f],
		}));
}

export function attachmentIcon(name: string) {
	const type = name.split('.')[name.split('.').length - 1];
	if (/docx|odt|doc|dot|dotx|docm|dotm/.test(type)) return IconFileText;
	else if (/xlsx|csv|xls|xlt|xla|xlts|xlsm|xltm|xlam|xlsb/.test(type)) return IconFileSpreadsheet;
	else if (/zip/.test(type)) return IconFileZip;
	else if (/pdf/.test(type)) return IconPdf;
	else if (/png|jpg|jpeg|gif/.test(type)) return IconPng;
	else return IconFileSignal;
}

export function removeArrayElement<T = any>(array: Array<T>, elm: T): Array<T> {
	const index = array.indexOf(elm);
	if (index > -1) {
		array.splice(index, 1);
	}
	return array;
}

export function arrayElementMove<T = any>(arr: Array<T>, old_index: number, new_index: number) {
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	return arr;
}

export function removeArrayElementByIndex<T = any>(array: Array<T>, index: number): Array<T> {
	if (index > -1) {
		array.splice(index, 1);
	}
	return array;
}

export function findDuplicates(arr: any[]) {
	return [...new Set(arr.filter((item, index) => arr.indexOf(item) != index))];
}

export function clean(str: string): any {
	if (typeof str !== 'string' || !str) return str;
	str = str.trim().replace('N/A', '').replace('n/a', '').replace(/,|;/g, ' ').replace(/’/g, "'").replace(/“/g, "'").replace(/”/g, "'");
	const toClean = /^[\s|#|\?|\!|&|`|"|\n|,\\|\*|.]|[\s|#|\?|\!|&|`|"|\n|,\\|\*|.]$/g;
	const result = str.replace(toClean, '');
	if (result.match(toClean)) {
		return clean(result);
	}
	return result;
}

export function capitalizeFirstLetter(str: string, withSpace?: boolean, needCleaning?: boolean): string {
	let text = str || '';
	if (needCleaning) {
		text = clean(str);
	}
	text = text.replace(/\b[a-z](?=[a-z])/g, (letter) => letter.toUpperCase());
	if (withSpace) return text.replace(/[A-Z]|\d+/g, ' $&');
	return text;
}

export function showNotification({
	data,
	message,
	type,
	caption,
	color,
	timeout,
	closeBtn,
	icon,
	loading,
}: {
	data?: ResponseInterface<any>;
	icon?: ReactNode;
	message?: ReactNode;
	type?: MantineColor;
	caption?: ReactNode;
	color?: MantineColor;
	timeout?: number;
	closeBtn?: boolean;
	loading?: boolean;
}) {
	notifications.show({
		title: message || data?.message,
		message: caption || data?.caption || '',
		color: color || type || (data?.isSuccess ? 'positive' : 'warning'),
		autoClose: timeout || 5000,
		withCloseButton: data?.closeBtn || closeBtn || true,
		icon,
		loading,
	});
}

export function toMultipartForm(data: FileUpload, field?: string) {
	const record: any = field ? { [field]: JSON.stringify(data) } : data;
	Object.keys(data)
		.filter((k) => data[k] instanceof File)
		.forEach((k) => {
			record[k] = data[k];
		});
	return toFormData(record);
}

export const dateRangeShortcuts = [
	{
		text: 'Today',
		value: () => [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
	},
	{
		text: 'Yesterday',
		value: () => [dayjs().subtract(1, 'day').format('YYYY-MM-DD'), dayjs().subtract(1, 'day').format('YYYY-MM-DD')],
	},
	{
		text: 'Last 7Days',
		value: () => [dayjs().subtract(6, 'days').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
	},
	{
		text: 'This Week',
		value: () => [dayjs().startOf('week').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
	},
	{
		text: 'Last Week',
		value: () => [dayjs().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'), dayjs().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')],
	},
	{
		text: 'This Month',
		value: () => [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
	},
	{
		text: 'Last Month',
		value: () => [
			dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
			dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
		],
	},
	{
		text: 'Last 30Days',
		value: () => [dayjs().subtract(30, 'days').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
	},
	{
		text: 'L. 3Months',
		value: () => [dayjs().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
	},
	{
		text: 'L. 6Months',
		value: () => [dayjs().subtract(5, 'month').startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
	},
	{
		text: 'L. 12Months',
		value: () => [dayjs().subtract(11, 'month').startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
	},
	{
		text: 'This Year',
		value: () => [dayjs().startOf('year').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
	},
	{
		text: 'Last Year',
		value: () => [dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'), dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')],
	},
];

export const playNotificationSound = () => {
	const audio = new Audio('/notification.mp3');
	audio.play();
};
export const playErrorSound = () => {
	const audio = new Audio('/error.mp3');
	audio.play();
};
